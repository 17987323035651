// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-motscles-js": () => import("./../../../src/pages/motscles.js" /* webpackChunkName: "component---src-pages-motscles-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-a-propos-page-js": () => import("./../../../src/templates/a-propos-page.js" /* webpackChunkName: "component---src-templates-a-propos-page-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-articles-page-js": () => import("./../../../src/templates/articles-page.js" /* webpackChunkName: "component---src-templates-articles-page-js" */),
  "component---src-templates-cgv-page-js": () => import("./../../../src/templates/cgv-page.js" /* webpackChunkName: "component---src-templates-cgv-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-motscle-js": () => import("./../../../src/templates/motscle.js" /* webpackChunkName: "component---src-templates-motscle-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-zi-frais-deplacement-page-js": () => import("./../../../src/templates/zi-frais-deplacement-page.js" /* webpackChunkName: "component---src-templates-zi-frais-deplacement-page-js" */)
}

